<template>
  <div class="banner-wrapper">
    <div class="container">
      <section class="wood-materials-banner">
        <div class="banner d-flex flex-column flex-md-row-reverse">
          <picture class="banner__image">
            <img src="@/assets/images/banners/wood-materials-banner.png" alt="banner" />
          </picture>
          <div
            class="banner__content d-flex justify-content-center align-items-center align-items-md-start flex-column-reverse text-center text-md-left"
          >
            <h2 class="banner__title">
              {{ t('banners.woodMaterials.title') }}
            </h2>
            <p class="banner__text">
              {{ t('banners.woodMaterials.description') }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormState from '@/hooks/formState'

export default defineComponent({
  name: 'WoodMaterialsPriceBanner',
  setup() {
    const { formState } = useFormState()
    const { t } = useI18n()

    return {
      formState,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.banner-wrapper {
  @media (min-width: 768px) {
    background: linear-gradient(180deg, $white-two 34.79%, $white 100%);
  }
}

.wood-materials-banner {
  background-color: $dark-blue;
  color: $white;
  margin: 0 -15px;

  @media (min-width: 768px) {
    margin: 0;
  }
}

.banner {
  &__content {
    padding: 20px 30px;

    @media (min-width: 768px) {
      margin-left: -2px;
      padding: 56px 36px 66px;
    }
  }

  &__title {
    color: $white;
    font-size: 24px;
  }

  &__text {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__image {
    mix-blend-mode: luminosity;

    @media (min-width: 768px) {
      flex: 0 0 45%;
      max-width: 45%;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 30%;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg, $dark-blue, transparent);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
