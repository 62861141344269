<template>
  <div class="form-wizard-step">
    <template v-if="hasWoodMaterials">
      <WoodMaterialsBanner />
    </template>

    <div class="form-wizard-content">
      <div class="container">
        <div class="wrapper">
          <MeasurementsForm :roof-type="selectedRoofType" :current="sides" :server-validation="serverValidation" />
        </div>
      </div>
    </div>

    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0">
            <button
              data-test="previous-step"
              @click="prevStep"
              :disabled="isLoading"
              class="btn btn-secondary btn-block"
            >
              {{ t('steps.measurements.buttons.prev') }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              data-test="next-step"
              @click="validateStep"
              :disabled="isLastStep || isLoading"
              class="btn btn-primary btn-block"
            >
              {{ !isLoading ? t('steps.measurements.buttons.next') : t('general.loading') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onUnmounted, reactive, ref, toRefs } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { isEmpty } from 'lodash/lang'
import { diff } from 'deep-object-diff'
import { useI18n } from 'vue-i18n'

import httpClient from '@/services/api/httpClient'
import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'
import useFormControl from '@/hooks/formControl'

import { LANGUAGE_CONFIG } from '@/services/language'

import MeasurementsForm from '@/components/Calculator/MeasurementsForm.vue'
import WoodMaterialsBanner from '@/components/Banners/WoodMaterialsBanner.vue'

export default defineComponent({
  name: 'MeasurementsStep',
  components: {
    MeasurementsForm,
    WoodMaterialsBanner
  },
  setup() {
    const { nextStep, prevStep, isLastStep } = useFormWizard()
    const { formState, updateFormState, getFieldValue } = useFormState()
    const { serverValidation } = useFormControl()
    const toast = useToast()
    const { t } = useI18n()

    const isLoading = ref(false)
    const selectedRoofType = ref(getFieldValue('roofType') || null)

    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE
    const hasWoodMaterials = LANGUAGE_CONFIG[currentLanguage].hasWoodMaterials

    const state = reactive({
      sides: getFieldValue('sides') || {}
    })

    onBeforeMount(() => {
      fetchStepData()
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && validateStep()

    const validations = useVuelidate()

    const fetchStepData = async () => {
      try {
        const { data } = await httpClient.get('steps', 'measurements', { roof_type: selectedRoofType.value.id })
        selectedRoofType.value = data.data

        if (!getFieldValue('sides') && selectedRoofType.value) {
          const data = {}
          selectedRoofType.value.sides.map(item => (data[item] = null))
          Object.assign(state.sides, data)
        }
      } catch (error) {
        toast.error(t('errors.data'))
      }
    }

    const validateStep = async () => {
      if (isLoading.value) return

      validations.value.$touch()

      if (validations.value.$invalid) return

      serverValidation.value = {}

      try {
        const stored = {
          sides: getFieldValue('sides') || {}
        }

        if (stored.sides && isEmpty(diff(stored, state))) {
          nextStep({ label: 'nem valtozott' })
          return Promise.resolve()
        }

        isLoading.value = true

        const payload = {
          roof_type: formState.roofType.id,
          sides: state.sides
        }

        await httpClient.post('validate_steps/measurements', payload)

        updateFormState({ sides: { ...state.sides } })

        nextStep({ label: JSON.stringify(state.sides) })
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404) {
          serverValidation.value = Object.assign({}, error.errData.data.params)
        } else {
          toast.error(t('erros.save'))
        }
      } finally {
        isLoading.value = false
      }
    }

    return {
      nextStep,
      prevStep,
      isLastStep,
      validateStep,
      roofType: formState.roofType,
      serverValidation,
      isLoading,
      selectedRoofType,
      ...toRefs(state),
      fetchStepData,
      hasWoodMaterials,
      t
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

h2 {
  @media screen and (max-width: 991px) {
    margin-bottom: 5px;
    margin-top: 60px;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 8px;
    margin-top: 80px;
  }
}

.roof-sides {
  padding: 0 40px;

  @media screen and (max-width: 991px) {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }
}

.roof-sides-form {
  margin-top: 40px;

  @media screen and (max-width: 991px) {
    width: 160px;
    margin-left: auto;
    margin-right: auto;
  }

  label {
    display: flex;
    text-align: left;
    font-family: $font-family-bold;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 2.4;
    letter-spacing: 0.71px;
  }
}

input {
  border-color: $pinkish-gray;
  border-radius: 0;
  padding: 15px;

  &::placeholder {
    color: $pinkish-gray;
  }

  @media screen and (max-width: 991px) {
    height: 50px;
  }

  @media screen and (max-width: 992px) {
    height: 40px;
  }
}
</style>
