<template>
  <div class="form-wizard-step">
    <div class="form-wizard-content text-center">
      <section>
        <div class="container-lg">
          <img
            v-if="gutterOptions.bannerImage"
            class="gutter-image"
            :src="gutterOptions.bannerImage"
            :alt="t('steps.gutter.title')"
          />
          <h2>{{ t('steps.gutter.title') }}</h2>
          <ul class="gutter-feature-list">
            <li class="gutter-feature-list-item" v-for="feature in gutterFeatures" :key="feature.id">
              <img :src="require(`@/assets/images/gutter/${feature.icon}.svg`)" :alt="feature.title" />
              <span v-html="feature.title" :style="feature?.highlight ? 'font-weight:bold' : ''" />
            </li>
          </ul>
          <GeneronOptionSelect
            :data="gutterOptions"
            :current="gutterOption"
            :server-validation="serverValidation"
            @on-select="setGutterOption"
          />
        </div>
      </section>
    </div>

    <div class="form-wizard-footer">
      <div class="form-wizard-actions container">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0">
            <button
              data-test="previous-step"
              @click="prevStep"
              :disabled="isLoading"
              class="btn btn-secondary btn-block"
            >
              {{ t('steps.gutter.buttons.prev') }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              data-test="next-step"
              @click="validateStep"
              :disabled="isLastStep || isLoading"
              class="btn btn-primary btn-block"
            >
              {{ isLoading ? t('general.loading') : t('steps.gutter.buttons.next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, onUnmounted, reactive, toRefs } from 'vue'
import { useToast } from 'vue-toastification'
import { isEmpty } from 'lodash/lang'
import { diff } from 'deep-object-diff'
import { useI18n } from 'vue-i18n'

import httpClient from '@/services/api/httpClient'
import useFormWizard from '@/hooks/formWizard'
import useFormState from '@/hooks/formState'
import useFormControl from '@/hooks/formControl'

import GeneronOptionSelect from '@/components/Calculator/GeneronOptionSelect'

export default defineComponent({
  name: 'GutterStep',
  components: {
    GeneronOptionSelect
  },
  setup() {
    const { nextStep, prevStep, isLastStep } = useFormWizard()
    const { updateFormState, getFieldValue } = useFormState()
    const { serverValidation } = useFormControl()
    const toast = useToast()
    const { t } = useI18n()

    const isLoading = ref(false)
    const gutterOptions = ref({})

    const gutterFeatures = [
      {
        id: 1,
        icon: 'feature-1',
        title: t('steps.gutter.features.first')
      },
      {
        id: 2,
        icon: 'feature-2',
        title: t('steps.gutter.features.second')
      },
      {
        id: 3,
        icon: 'feature-3',
        title: t('steps.gutter.features.third')
      },
      {
        id: 4,
        icon: 'feature-4',
        title: t('steps.gutter.features.fourth')
      },
      {
        id: 5,
        icon: 'feature-5',
        title: t('steps.gutter.features.fifth')
      },
      {
        id: 6,
        icon: 'feature-6',
        title: t('steps.gutter.features.sixth')
      }
    ]

    const state = reactive({
      gutterOption: getFieldValue('gutterOption') || {}
    })

    onBeforeMount(() => {
      fetchStepData()
      document.addEventListener('keypress', submitHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('keypress', submitHandler)
    })

    const submitHandler = e => e.key === 'Enter' && validateStep()

    const fetchStepData = async () => {
      try {
        const { data } = await httpClient.get('steps/gutter')
        gutterOptions.value = data.data
      } catch (error) {
        toast.error(t('errors.data'))
      }
    }

    const validateStep = async () => {
      if (isLoading.value) return

      if (!state.gutterOption.id) {
        toast.warning(t('errors.select'))
        return
      }

      serverValidation.value = {}

      try {
        const stored = {
          gutterOption: getFieldValue('gutterOption') || false
        }

        if (stored.gutterOption && isEmpty(diff(stored, state))) {
          nextStep({ label: 'nem valtozott' })
          return Promise.resolve()
        }

        isLoading.value = true

        const data = {
          gutter_option: state.gutterOption.id
        }

        await httpClient.post('validate_steps/gutter', data)

        updateFormState({ gutterOption: state.gutterOption })

        nextStep({ label: state.gutterOption.label })
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404) {
          serverValidation.value = Object.assign({}, error.errData.data.params)
        } else {
          toast.error(t('errors.save'))
        }
      } finally {
        isLoading.value = false
      }
    }

    const setGutterOption = async gutterOption => {
      state.gutterOption = gutterOption
    }

    return {
      nextStep,
      prevStep,
      isLastStep,
      gutterOptions,
      setGutterOption,
      validateStep,
      ...toRefs(state),
      serverValidation,
      isLoading,
      t,
      gutterFeatures
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

h2 {
  margin-top: 40px;

  @media (min-width: 576px) {
    margin-top: 60px;
  }
}

.gutter-image {
  width: 100%;
  max-width: 100%;
  min-height: 250px;
  height: 250px;
  object-fit: cover;
}

.gutter-feature-list {
  max-width: 620px;
  margin: 20px auto 10px auto;
  list-style-type: none;
  text-align: left;
}

.gutter-feature-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  line-height: 1.57;

  img {
    margin-right: 10px;
  }
}

.gutter-feature-asterisk {
  text-align: left;
  max-width: 630px;
  margin: 0 auto;
  font-size: 0.75em;
}
</style>
