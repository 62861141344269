<template>
  <div class="form-wizard-step">
    <div v-if="results">
      <section class="roof-section">
        <div class="container">
          <h1 class="page-section__title">
            <span class="page-section__title__background">{{ results.tile.title }}</span>
            <span class="page-section__title__main">{{ results.tile.color }}</span>
          </h1>
          <div class="roof-summary">
            <div class="row">
              <div class="col-md-4 order-1 order-md-0 mb-4 mb-md-0">
                <ul class="roof-summary__list">
                  <li>
                    <span class="roof-summary__label">{{ t('general.surface') }}</span>
                    <strong>{{ results.surface }}</strong>
                  </li>
                  <li>
                    <span class="roof-summary__label">{{ t('steps.summaryStep.terms.warranty') }}</span>
                    <strong>{{ results.tile.warranty }} {{ t('general.year') }}</strong>
                  </li>
                  <li>
                    <span class="roof-summary__label">{{ t('steps.summaryStep.terms.gross') }}</span>
                    <strong>{{ results.tile.gross }} {{ t('general.currency') }}</strong>
                  </li>
                </ul>
                <button @click="changeTileShape" class="btn btn-primary no-print">
                  {{ t('steps.summaryStep.buttons.changeTileShape') }}
                </button>
              </div>
              <div class="col-md-4 order-0 order-md-1">
                <div class="roof-summary__image text-center">
                  <img :src="results.tile.image" :alt="results.tile.title" />
                </div>
              </div>
              <div class="col-md-4 order-2">
                <ul class="roof-summary__list">
                  <li>
                    <span class="roof-summary__label">{{ t('steps.summaryStep.terms.roofType') }}</span>
                    <strong>{{ results.roof_type.title }}</strong>
                  </li>
                  <li v-for="(side, index) in results.sides" :key="index">
                    <span class="roof-summary__label">
                      {{ t(`general.roofSides.${index}`) }} ({{ getRoofSideLabel(index) }})
                    </span>
                    <strong>{{ side }} m</strong>
                  </li>
                  <li>
                    <span class="roof-summary__label">{{ t('steps.summaryStep.terms.degree') }}</span>
                    <strong>{{ results.degree }}°</strong>
                  </li>
                </ul>
                <button @click="initNewCalculation" class="btn btn-danger no-print">
                  {{ t('steps.summaryStep.buttons.newCalculation') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <template v-if="formState.generonOption?.interested">
        <div class="container">
          <GeneronRoofPriceBanner />
          <template v-if="hasWoodMaterials">
            <WoodMaterialsPriceBanner />
          </template>
          <MaterialRequirementsBanner />
          <template v-if="hasEnvirosense">
            <EnvirosenseBanner />
          </template>
          <!-- <GeneronCarousel />-->
          <!-- <GeneronInfoTable />-->
        </div>
        <template v-if="hasTetotepitek">
          <TetotepitekBanner />
        </template>
      </template>

      <template v-else>
        <div class="container">
          <RoofPriceBanner v-if="formState.results?.price" />
          <template v-if="hasWoodMaterials">
            <WoodMaterialsPriceBanner />
          </template>
          <MaterialRequirementsBanner />
          <template v-if="hasEnvirosense">
            <EnvirosenseBanner />
          </template>
        </div>
        <template v-if="hasTetotepitek">
          <TetotepitekBanner />
        </template>
        <!--        <GeneronBanner v-if="results.generon_banner" />-->
      </template>

      <section v-if="results.banners && showBanners" class="banners-section no-print">
        <div class="container">
          <div class="d-flex flex-wrap justify-content-center">
            <DefaultBanner v-for="banner in results.banners" :key="banner.id" :banner="banner" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormState from '@/hooks/formState'
import useGtmEvent from '@/hooks/gtm'
import { LANGUAGE_CONFIG } from '@/services/language'

import DefaultBanner from '@/components/Banners/DefaultBanner.vue'
// import GeneronBanner from '@/components/Banners/GeneronBanner.vue'
import MaterialRequirementsBanner from '@/components/Banners/MaterialRequirementsBanner.vue'
import WoodMaterialsPriceBanner from '@/components/Banners/WoodMaterialsPriceBanner.vue'
import RoofPriceBanner from '@/components/Banners/RoofPriceBanner.vue'
import GeneronRoofPriceBanner from '@/components/Banners/GeneronRoofPriceBanner.vue'
// import GeneronCarousel from '@/components/Generon/GeneronCarousel.vue'
// import GeneronInfoTable from '@/components/Generon/GeneronInfoTable.vue'
import EnvirosenseBanner from '@/components/Banners/EnvirosenseBanner.vue'
import TetotepitekBanner from '@/components/Banners/TetotepitekBanner'

export default defineComponent({
  name: 'SummaryStep',
  components: {
    DefaultBanner,
    // GeneronBanner,
    MaterialRequirementsBanner,
    WoodMaterialsPriceBanner,
    RoofPriceBanner,
    GeneronRoofPriceBanner,
    // GeneronCarousel,
    // GeneronInfoTable,
    EnvirosenseBanner,
    TetotepitekBanner
  },
  setup() {
    const { formState, updateFormState } = useFormState()
    const { trackEvent } = useGtmEvent()
    const { t } = useI18n()

    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE
    const showBanners = LANGUAGE_CONFIG[currentLanguage].showBanners
    const hasEnvirosense = LANGUAGE_CONFIG[currentLanguage].hasEnvirosense
    const hasTetotepitek = LANGUAGE_CONFIG[currentLanguage].hasTetotepitek
    const hasWoodMaterials = LANGUAGE_CONFIG[currentLanguage].hasWoodMaterials

    function getRoofSideLabel(side) {
      return side.split('_')[1].toUpperCase()
    }

    function initNewCalculation() {
      trackEvent({
        category: 'új kalkuláció',
        action: 'kattintas'
      })
      window.open(window.location.origin, '_blank')
    }

    function changeTileShape() {
      const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE
      const TILE_SHAPE_STEP = LANGUAGE_CONFIG[currentLanguage].steps.findIndex(e => e === 'TileShape')

      trackEvent({
        category: 'cserép módosítása',
        action: 'kattintas'
      })

      updateFormState({ results: null, tile: null, tileShape: null })
      localStorage.setItem('tc_form', JSON.stringify({ ...formState }))

      window.open(`${window.location.href}?step=${TILE_SHAPE_STEP}`, '_blank')
    }

    return {
      results: formState.results,
      getRoofSideLabel,
      initNewCalculation,
      changeTileShape,
      t,
      showBanners,
      hasEnvirosense,
      hasTetotepitek,
      hasWoodMaterials,
      formState
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.banners-section {
  padding: 40px 0;

  @media (min-width: 991px) {
    padding: 80px 0 50px;
  }
}

.roof-section {
  padding-top: 40px;
}

.roof-summary {
  margin-top: 20px;

  .btn {
    @media (max-width: 765px) {
      display: block;
      width: 100%;
    }
  }

  &__list {
    padding: 0;
    margin-bottom: 20px;

    li {
      display: flex;
      padding: 8px 0;

      &:not(:last-child) {
        border-bottom: 2px solid $white-two;
      }

      .roof-summary__label {
        width: 50%;
      }

      strong {
        font-family: $font-family-bold;
      }
    }
  }

  &__image {
    @media print {
      img {
        max-height: 350px !important;
      }
    }
  }
}
</style>
